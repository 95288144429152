import React from 'react';

import p_clientLogo from "../assets/clientLogo.png"
import p_clientLogoMenu from "../assets/clientLogoMenu.png"
import p_clientLogo512 from "../assets/clientLogo512.png"



// Parse.initialize("YOUR_APP_ID", "YOUR_JAVASCRIPT_KEY");
export const clientLogo = `${p_clientLogo}`
export const clientLogoMenu = `${p_clientLogoMenu}`
export const clientLogo512 = `${p_clientLogo512}`



// TW-3-TWBETA
export const backAppId = "0X4UAGcZNp1vWK6clOiokylwf6wWz11bzzmGME7c"
export const backJSId = "kqyaRigg8on4JSrjhKejD7asDZbagdw9lA2KVVII"

// export const backMasterKey = "Cr3vbJcSs0NMRpHmhOmInIDZzSPeutsPT9xay0zx"


// export const hasShop = false

export const defaultStoreObjid = "FC237G0HJG"  // Main Store
export const defaultUserObjid = "hywLGNUCi6"  // ROY

// export const standardDeliveryTime = "Standard Delivery Time of 2-3 weeks"
// export const freeShippingThreshold = 300
// export const standardPaidShippingPrice = 35


// FOR CONCIERGE
export const awsBaseUrl = "https://tailorwise.s3.eu-west-2.amazonaws.com/concierge/tw-main/"
export const clientName = "TW Master"

export const clientPhone = "+91-09999999999999"
export const clientEmail = "info@tailorwise.com"
export const clientEmailFromId = "no-reply@tailorwise.com"  //TW no-reply-email or client validated email with us
export const clientCCEmail = ""

export const hasFactory = true
export const isSingleLocation = false
export const hasAgents = false


// export const clientEmail = "appsquare.in@gmail.com"
// export const clientEmailFromForOrder = "appsquare.in@gmail.com"



export const clientWebsite = "https://tailorwise.com"
export const clientTermsAndConditionsUrl = "https://tailorwise.com/terms-conditions"


// KM https://tailorwise.s3.eu-west-2.amazonaws.com/clientassets/karanmoin/twLogoHome-black.png
// export const clientLogoUrl = "https://tailorwise.s3.eu-west-2.amazonaws.com/clientassets/tw-mastermain/tw-logo-140x30.svg"
// export const clientMenuLogoUrl = "https://tailorwise.s3.eu-west-2.amazonaws.com/clientassets/tw-mastermain/tw-logo-140x30.svg"

export const clientLogoUrl = "https://tailorwise.s3.eu-west-2.amazonaws.com/clientassets/tw-mastermain/tw-logo-140x30.svg"
export const clientMenuLogoUrl = "https://tailorwise.s3.eu-west-2.amazonaws.com/clientassets/tw-mastermain/tw-logo-140x30.svg"

export const currencySymbol = "$"
export const currencyCode = "USD"
export const clientBGHome = ""
export const clientBGHero = ""



export const client_defaultCountry = 'IN (+91)'
export const client_defaultCountryOption = () => {
    return (
        <option value={client_defaultCountry} >{client_defaultCountry}</option>

    )
}

export const client_favCountryList = () => {

    // FIRST OPTION SHOULD BE SAME AS DEFAULT COUNTRY

    return (
        <>
            {client_defaultCountryOption()}
            <option value='US (+1)'  >US (+1)</option>
            <option value='CA (+1)'  >CA (+1)</option>
            <option value='AU (+61)'  >AU (+61)</option>
            <option value='GB (+44)'  >GB (+44)</option>
            <option value='AE (+971)'  >AE (+971)</option>
            <option value='ZW (+263)'  >ZW (+263)</option>
            <option value='ZA (+27)' >ZA (+27)</option>
        </>
    )

}
